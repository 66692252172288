import { Store } from "./src/redux/store";


export const onClientEntry = () => {
  const script = document.createElement('script');
  const head = document.querySelector('head') || document.body;
  script.src = 'https://acsbapp.com/apps/app/dist/js/app.js';
  script.async = true;
  script.onload = () => {
    window.acsbJS.init({
      statementLink: '',
      footerHtml: '',
      hideMobile: false,
      hideTrigger: false,
      disableBgProcess: false,
      language: 'en',
      position: 'left',
      leadColor: '#146FF8',
      triggerColor: '#146FF8',
      triggerRadius: '50%',
      triggerPositionX: 'left',
      triggerPositionY: 'bottom',
      triggerIcon: 'people',
      triggerSize: 'bottom',
      triggerOffsetX: 20,
      triggerOffsetY: 20,
      mobile: {
        triggerSize: 'small',
        triggerPositionX: 'left',
        triggerPositionY: 'bottom',
        triggerOffsetX: 10,
        triggerOffsetY: 10,
        triggerRadius: '20',
      },
    });
  };
  head.appendChild(script);
};

export const wrapRootElement = Store;
